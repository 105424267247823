import { useCallback, useEffect, useState } from "react";
import { ERRORS } from "../helpers/errors";
import { log } from "../helpers";

const useParamsForCustomizable = () => {
  const [params, setParams] = useState({});
  const [getOptions, setOptions] = useState({});
  const [isRendered, setIsRendered] = useState(false);

  const updateParams = useCallback((args) => {
    setParams((prevState) => ({ ...prevState, ...args }));
  }, []);

  const updateOptions = useCallback((args) => {
    setOptions((prevState) => ({ ...prevState, ...args }));
  }, []);

  useEffect(() => {
    try {
      const urlParams = new URL(window.location.href).searchParams;
      const { url, ci, software, addon } = Object.fromEntries(urlParams);
      addon === "true" && updateOptions({ advancedBoosterPackDefault: true });
      software && updateParams({ software });
      ci && updateParams({ ci });
      url && updateParams({ url });
    } catch (error) {
      log(error, ERRORS.URL_ERRORS.ERROR_PARSING_URL_PARAMETERS, true);
    } finally {
      setIsRendered(true);
    }
  }, [updateOptions, updateParams]);

  const getParams = new URLSearchParams(params).toString();
  return { getParams, getOptions, isRendered };
};

export default useParamsForCustomizable;
