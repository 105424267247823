import React, { useRef } from "react";
import LayoutIframe from "../layouts/LayoutIframe";
import Seo from "../components/Seo";
import OgImg from "../assets/images/raster/components/common/mainscreen.png";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import CustomizableContainer from "../containers/Paywalls/Customizable/CustomizableContainer";
import useEventFromExt from "../hooks/useEventFromExt";
import useParamsForCustomizable from "../hooks/useParamsForCustomizable";

const ExtCustomizableTemplate = ({ pageContext }) => {
  const { i18n } = pageContext;
  const pricingRef = useRef();
  const { t } = useTranslation();
  const { getOptions, getParams, isRendered } = useParamsForCustomizable();
  useEventFromExt();

  return isRendered ? (
    <LayoutIframe withTagManager={false} className="TeECT" turnOnEventView>
      <Seo
        title="AMZScout: Accurate Amazon Product Finder & Research Tool"
        description="Find an Amazon FBA product and niche that will make you from $3,000/mo. Full info about demand and competitors. Trusted by 250,000 Amazon sellers. Click to learn more."
        page={i18n.path}
        manifest="browserconfig.xml"
        ogImg={OgImg}
      />
      <CustomizableContainer
        pricingRef={pricingRef}
        customClass="TeECT-Pricing"
        language={t("code")}
        getParams={getParams}
        mode="iframe"
        {...getOptions}
      />
    </LayoutIframe>
  ) : null;
};
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { eq: "customizable" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default ExtCustomizableTemplate;
